<template>
  <div
      class="fill-height d-flex justify-center"
      :class="[
                { 'align-center' :  element.config.valign === 'center'  },
                { 'align-baseline' : !element.config.valign || element.config.valign === 'top'  },
                { 'align-end' :  element.config.valign === 'bottom'  }
            ]"
      :style="backgroundStyle"
       >

    <div style="width: 100%;" ref="containerRef"
         :style="elementStyle"
         :id="'ae_element_' + element.id"

    >
      <quill-editor
          @input="$emit('allow-edit-text' , true)"
          v-model="text"
          v-show="ready"
          @focus="$emit('focus' , true)"
          @blur="blur"
          :id="`quill_${element.id}`"
          style="width: 100%; padding: 0 !important; margin: 0 !important;"
          :options="editorOptions"
          ref="quillRef"
          :disabled="disabled"
          :style="disabled ? `user-select : none` : null"
          :key="`quill_${element.id}`"
      />

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";


import {quillEditor} from 'vue-quill-editor'
// import Quill from 'quill'
import 'quill/dist/quill.core.css'
export default {
  name: "elementTextEditor",
  components : {
    quillEditor
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    triggerDeselect : {
      type : Number
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    },
    toolbarOpened : {
      type : Boolean,
      default : false
    },
    quill : {},
    cellWidth : {},
    isReady : {},
    isSelected : {
      type : Boolean,
      default : false
    },
    disabled : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      text : '',
      ready : false,
      height : 0,
      quillVar : null,
      isDisabled : true,

      editorOptions: {
        scrollingContainer: null,
        modules : {
          toolbar : false
        },
        theme: null,
        fontSize: '16px',
      },
      element : { config : {}},

      processStyleTimeout : null
    }
  },
  computed : {
    siteConfig() {
      return this.$store.state.avalon.config
    },
    domElement() {
      return this.$refs.containerRef
    },
    config() {
      return this.element.config || {}
    },
    backgroundStyle() {
      let style = ''

      if ( this.element.config.is_outlined ) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important ;`
        if ( this.element.config.border_style ) {
          style += `border-style : ${this.element.config.border_style } !important ;`
        }
      }

      if ( this.element.config && this.element.config.has_background ) {
        style += `background : ${this.GET_AVALON_COLOR(this.element.config.background_color)}; `
      }

      if ( this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      return style
    },

    elementStyle() {
      let style = ''

      if ( !this.element.config ) {
        return ''
      }

      if ( this.element.config.padding_top) {
        style += `padding-top : ${this.element.config.padding_top}px;  `
      }
      if ( this.element.config.padding_bottom) {
        style += `padding-bottom : ${this.element.config.padding_bottom}px;  `
      }
      if ( this.element.config.padding_left) {
        style += `padding-left : ${this.element.config.padding_left}px;  `
      }
      if ( this.element.config.padding_right) {
        style += `padding-right : ${this.element.config.padding_right}px;  `
      }

      if ( !this.isSelected ) {
        style +=  `pointer-events: none; `

      }

      return style
    }
  },
  watch : {

    'siteConfig.colors' : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
    'siteConfig.styles' : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
    'siteConfig.fonts' : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
    block : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
    height() {
      if ( !this.isReady ) {
        setTimeout(()=> {
          if ( this.$store.state.avalon.recalculateSizes ) {
            // this.notify(' i am text and i  will update  height')
            this.$emit('actual-height-update' , this.height)
            this.$emit('update-height', this.height )
          }
        } , 350)
        return
      }
      if ( this.$store.state.avalon.recalculateSizes ) {
        // this.notify(' i am text and i  will update  height')
        this.$emit('actual-height-update' , this.height)
        this.$emit('update-height', this.height )
      }
    },
    triggerDeselect() {
      const selection = window.getSelection()
      selection.removeAllRanges();
    },
    cellWidth()  {
      this.getHeight()
      this.$emit('actual-height-update' , this.height)
      this.$emit('update-height', this.height )
    },
    'block.config.gridGapVertical'() {
      this.getHeight()
      this.$emit('actual-height-update' , this.height)
      this.$emit('update-height', this.height )
    },
    'block.config.columns'() {
      this.getHeight()
      this.$emit('actual-height-update' , this.height)
      this.$emit('update-height', this.height )
    },
    MOBILE_VIEW() {
      this.processTextStyling()
    },
    WINDOW_WIDTH() {
      if ( this.EDITOR_FONT_COEFFICIENT(this.blockWidth) < 1 && !this.SM ) {
        this.processTextStyling()
        if ( this.processStyleTimeout ) {
          clearTimeout(this.processStyleTimeout)
        }

        this.processStyleTimeout = setTimeout(()=> {
          this.processTextStyling()
        } , 300)
      }
    },
    value : {
      handler() {
        if ( this.value.width !== this.element.width ){
          this.getHeight()
        }

        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    },
    config : {
      handler() {
        this.getHeight()
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

    updateHeight() {
      this.$emit('actual-height-update' , this.height)
      if ( this.firstLoad ) {
        return
      }
      this.$emit('update-height', this.height )
    },

    blur() {
      if ( this.toolbarOpened ) {
        return
      }
      this.$emit('focus' , false);
      this.updateElementText()
    },
    updateElementText() {
      this.element.text = this.text
      this.$emit('input' , this.element)
    },
    getHeight() {
      let height = this.domElement.clientHeight
      this.height = height
    },
    processTextChange() {
      this.getHeight()
      this.processTextStyling()
    },

    processTextStyling() {
      this.PROCESS_TEXT_STYLES(`quill_${this.element.id}` , this.block.config.style || 'light_1' ,   true , false, this.block.config.width || 1240)
    },

    initQuill() {
      setTimeout(()=> {

        this.$emit('quillReady' , this.$refs.quillRef.quill )
        this.$nextTick(()=>{

          this.quill.root.style.fontSize = this.MOBILE_VIEW && this.config.mobile_resize ? '14px' : '16px' ;


          this.quill.on('text-change', this.processTextChange );
          this.$emit('ready')
          setTimeout( ()=> {
            this.processTextStyling()
            this.ready = true
          } , 100  )
          setTimeout(() => this.getHeight() , 500)
        })

      })
    },

  },
  mounted(){

    if ( this.value ) {
      this.element = this.COPY(this.value)
      this.text = this.element.text
    }

    setTimeout(() => {
      this.getHeight()
      this.$emit('actual-height-update' , this.height)
    }, 200)

    this.initQuill()

  },
  beforeDestroy() {
    this.quill.off('text-change', this.processTextChange );
  }

}
</script>

<style scoped lang="scss">
@import "~@/assets/css/quill-disable-padding.css";
</style>